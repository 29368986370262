import React from "react";
import "../styles/styles.css";


function Footer() {
    return (
      <div className="Footer">
        <p><b>© 2021 <a href="https://kontaktdagarna.se" target="_blank" rel="noopener noreferrer"> Kontaktdagarna</a></b></p>
      </div>
    );
  }
  
  export default Footer;