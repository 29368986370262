import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAJYuPFIY8Yqd3-J2KA3Z-Lm0JZh6Gb0h0",
  authDomain: "kontaktkampen-d11ee.firebaseapp.com",
  projectId: "kontaktkampen-d11ee",
  storageBucket: "kontaktkampen-d11ee.appspot.com",
  messagingSenderId: "9761188212",
  appId: "1:9761188212:web:00cac26f9704706c0ba421",
  measurementId: "G-NYYPEW8HZE"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const fireB = firebase;
export const db = firebase.firestore();
export const functions = firebase.app().functions("europe-west1");
